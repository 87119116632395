import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import * as apiUtility from '../../config/APIUtils';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import UserBadgeUrlDialog from './UserBadgeUrlDialog';
import Spinner from 'react-bootstrap/Spinner';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { IconButton, Tooltip, Typography } from '@mui/material';
import SuccessPopUp from '../Utility/SuccessPopUp';
import ErrorPopUp from '../Utility/ErrorPopUp';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { Table } from '@mui/joy';
import RemoveShoppingCartOutlinedIcon from '@mui/icons-material/RemoveShoppingCartOutlined';

function UserCrsVersionsTable(props) {

    var training_providers = JSON.parse(sessionStorage.getItem("training_providers"));

    const [courseList, setCourseList] = useState([{ '': '' }]);
    const [modalInfo, setmodalInfo] = useState('');
    const [show, setshow] = useState(false);
    const [dataForModal, setdataForModal] = useState({});
    const [selectedCourseValidityDuration, setselectedCourseValidityDuration] = useState('');
    const [selectedCourseId, setSelectedCourseId] = useState('');
    const [selectedCrsVersionId, setSelectedCrsVersionId] = useState('');
    const [badgeUrlResponse, setbadgeUrlResponse] = useState('');
    const [showSpinner, setshowSpinner] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMsg, setsuccessMsg] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [cartErrorBox, setCartErrorBox] = useState(false);
    const [cartErrorBoxList, setCartErrorBoxList] = useState([]);
    const [cartErrorBoxMsg, setCartErrorBoxMsg] = useState("");
    const [currentRowAdded, setCurrentRowAdded] = useState({});


    const BASE_URL_GET_ALLCOURSE_VERSIONS = "course/getCourseVersion/";
    const URL_UPDATE_TRAINING_CART = "user/update-pending-course-cart";

    //const BASE_URL_SAVE_BADGE_URL = "user/saveBadgeUrl/";
    var params = useParams().id;
    //console.log("Params in Admin : ", params, props.userId);
    if (params === undefined || params === "") {
        params = props.userId;
    }
    var mapOfIssueOrgType = {};
    training_providers.forEach(element => {
        mapOfIssueOrgType[element.id] = element.value;
    });

    var training_providers = JSON.parse(sessionStorage.getItem("training_providers"));

    var mapOfTpIdName = {};
    if (training_providers !== undefined && training_providers !== null) {
        training_providers?.forEach(element => {
            mapOfTpIdName[element.tp_id] = element.provider_name;
        });
    } else {
        mapOfTpIdName["1001"] = "Lambton College";
    }
    //console.log("Next in Admin : ", params);
    useEffect(() => {
        setshowSpinner(true)

        setselectedCourseValidityDuration(props.data.validity)
        const getUserHomeRoleData = BASE_URL_GET_ALLCOURSE_VERSIONS + props.data.crsId + "/" + params;
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = getUserHomeRoleData;
        apiUtility.fetchAPIDataGET(apiRequest).then(response => {
            //console.log(" All COURSE version ========== > ", response.data);
            setCourseList(response.data)
            setshowSpinner(false)

        });
        //props.handleUpdateCallback(props.data);
    }, [params]);

    const reloadVersionGrid = (passCourseId) => {
        const getUserHomeRoleData = BASE_URL_GET_ALLCOURSE_VERSIONS + props.data.crsId + "/" + params;
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = getUserHomeRoleData;
        apiUtility.fetchAPIDataGET(apiRequest).then(response => {
            //console.log(" Refresh grid : All COURSE version ========== > ", response.data);
            setCourseList(response.data)
            setshowSpinner(false)

        });
        if(passCourseId !== undefined & passCourseId === false)
            props.handleUpdateCallback();
        else
            props.handleUpdateCallback(props.data.crsId);
    }

    const handleShow = () => {
        setshow(true)
    };
    const func2 = (params, roleId, roleName, trainDuration, validity, crsId, title, reqFrom, badgeUrl, crsVersionId) => {
        var val = params + "~~~" + roleId + "~~~" + roleName + "~~~" + trainDuration + "~~~" + validity + "~~~" + crsId + "~~~" + title + "~~~" + reqFrom + "~~~" + badgeUrl + "~~~" + crsVersionId;
        setSelectedCourseId(crsId);
        setSelectedCrsVersionId(crsVersionId);
        setmodalInfo(val);
    }

    const updatePendingCart = () => {
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = URL_UPDATE_TRAINING_CART;
        apiRequest.data = {
            "userId": params,
            "courseObj": currentRowAdded,
            "isRejected": false,
            "isRemovePending": false,
            "toBeDeleted":cartErrorBoxList
        }
        apiUtility.sendAPIDataPOST(apiRequest).then(response => {
            if (response.data.alreadyExist) {
                setErrorMsg("Processing error");
                setShowErrorMsg(true);
            } else {
                let deletedCourseOb = "";
                let pendingList = (props.activeCartList["pending"] != undefined && props.activeCartList["pending"].length > 0) ? props.activeCartList["pending"] : []
                pendingList.forEach(element => {
                    if (element.tp_course_code === cartErrorBoxList[0].tp_course_code) {
                        deletedCourseOb= element;
                    }
                });
                //console.log("1. Added : ",currentRowAdded);
                
                props.handleCourseCartCallback(currentRowAdded, false, false);//added

                //console.log("1. Deleted course : ", deletedCourseOb);
                props.handleCourseCartCallback(deletedCourseOb, false, true, false);//removed from pending
                setCartErrorBox(false);
                setsuccessMsg("Updated ");
                setShowSuccess(true);
                reloadVersionGrid();
            }
        });
    }

    const createTrainingCourseObj = (rowData) => {
        return {
            "courseId": rowData.crsId,
            "courseVersionId": rowData.crsVersionId,
            "tp_course_code": rowData.tp_course_code,
            "tp_id": parseInt(rowData.orgIssued),
            "versionNo": rowData.versionNo,
            "trainDuration": rowData.trainDuration,
            "equivalentCrsStatus": rowData.equivalentCrsStatus,
            "training_url": rowData.training_url,
            "submitted_date": "",
            "validity": rowData.validity,
            "payeeHome": "",
            "role": "",
            "status": false
        }

    }

    const addCoursesToCart = (row) => {
        let cartCourseObj = createTrainingCourseObj(row);
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = URL_UPDATE_TRAINING_CART;
        apiRequest.data = {
            "userId": params,
            "courseObj": cartCourseObj,
            "isRejected": false,
            "isRemovePending": false
        }
        //let obj = {};
        apiUtility.sendAPIDataPOST(apiRequest).then(response => {
            //console.log("Response : ", response);
            if (response.data.alreadyExist) {
                setCurrentRowAdded(cartCourseObj);
                setCartErrorBoxMsg(response.data.errorReason);
                setCartErrorBoxList(response.data.courses);
                setCartErrorBox(true);
            } else {
                // obj = {
                //     type: "addedToPending",
                //     data: cartCourseObj
                // }
                //dispatch(handleCartUpdate(obj));
                //console.log("Called : ", obj);
                props.handleCourseCartCallback(cartCourseObj, false, false);
                reloadVersionGrid();
                setsuccessMsg("Added Successfully");
                setShowSuccess(true);
            }
        });
    }

    const courseCodeUrlFormatter = (data, row) => {
        let courseVersionsAdded = false;
        let pendingList = (props.activeCartList["pending"] != undefined && props.activeCartList["pending"].length > 0) ? props.activeCartList["pending"] : []
        pendingList.forEach(element => {
            if (element.courseVersionId === row.crsVersionId) {
                courseVersionsAdded = true;
            }
        });
        if (row.training_url !== undefined && row.training_url !== "") {
            return <Typography variant='body' style={{ width: '8rem', cursor: 'pointer', display: 'flex' }} onClick={() => { window.open(row.training_url, "_blank") }}>
                <OpenInNewOutlinedIcon color='primary' style={{ fontSize: '1.2pc' ,  marginTop:'2px'}} />&nbsp;{row.tp_course_code}&nbsp;{(row.direct_registration) && <VerifiedIcon style={{fontSize:'1.3pc', marginTop:'2px'}}  color={(courseVersionsAdded)?'success':'primary'} />}
            </Typography>;
        } else {
            return <Typography variant='body' style={{ width: '8rem' }}>
                {row.tp_course_code}&nbsp;{(row.direct_registration) && <VerifiedIcon style={{fontSize:'1.3pc', marginTop:'2px'}}  color={(courseVersionsAdded)?'success':'primary'} />}
            </Typography>;
        }
    };


    const applyButtonFormatter = (data, row) => {
        let alreadyAddedFlag = false;
        let pendingList = (props.activeCartList["pending"] != undefined && props.activeCartList["pending"].length > 0) ? props.activeCartList["pending"] : []
        pendingList.forEach(element => {
            if (element.courseVersionId === row.crsVersionId) {
                alreadyAddedFlag = true;
            }
        });
        if (row.direct_registration == false) {
            return <Tooltip variant="contained" title="Not eligible for direct registration">
                <IconButton color="danger" aria-label="directions-down">
                    <DoNotDisturbIcon />
                </IconButton>
            </Tooltip>
        } else if (alreadyAddedFlag) {
            return <Tooltip variant="contained" title="Added">
                <IconButton color="success" aria-label="directions-down">
                    <AddTaskIcon />
                </IconButton>
            </Tooltip>
        } else {
            return <Tooltip variant="contained" title="Add to Training Cart">
                <IconButton color="primary" onClick={() => { addCoursesToCart(row); }} aria-label="directions-down">
                    <AddShoppingCartIcon />
                </IconButton>
            </Tooltip>
        }
    };

    const buttonFormatter = (data, row) => {
        row["validFor"] = selectedCourseValidityDuration;
        //row["orgIssued"] = mapOfIssueOrgType[row.orgIssued]
        if (row.status === "Pending") {
            return <Button variant="warning" onClick={function (event) {
                setdataForModal(row);
                handleShow(); setbadgeUrlResponse(''); func2(params, row.roleId, row.roleName,
                    row.trainDuration, row.valid, row.crsId, row.title, row.status, row.badgeUrl, row.crsVersionId);
                //checkIfApplied(row.crsId, row.crsVersionId, row.badgeUrl);
            }}>
                History
            </Button>;
        } else {
            return <Button variant="success" onClick={function (event) {
                handleShow(); setbadgeUrlResponse(''); setdataForModal(row); func2(params, row.roleId, row.roleName,
                    row.trainDuration, row.valid, row.crsId, row.title, row.status, row.badgeUrl, row.crsVersionId);
            }}>
                History
            </Button>;
        }
    };


    const issueOrgFormatter = (data, row) => {
        //console.log("Issue Org : ", mapOfTpIdName, row.orgIssued,"=>", mapOfTpIdName[parseInt(row.orgIssued)]);
        let tpName = mapOfTpIdName[parseInt(row.orgIssued)];
        //console.log("TP Name : ",tpName);
        return tpName;
    };

    const crsStatusFormatter = (data, row) => {
        return ((row.equivalentCrsStatus !== "Approved")?row.equivalentCrsStatus:"");
    };
    
    const expiryDateFormatter = (data, row) => {
        return ((row.equivalentCrsStatus !== "Approved" && row.equivalentCrsStatus !== "Enrolled")?row.validity:"");
    };

    const columns = [
        {
            dataField: "tp_course_code",
            text: "Version Code",
            sort: true,
            formatter: courseCodeUrlFormatter,
            style: {
                fontStyle: 'italic',
                height: '3%',
                width: "7rem"
            }

        },
        {
            dataField: "versionNo",
            text: "Version No",
        },
        {
            dataField: "title",
            text: "Title",
        },
        {
            dataField: "trainDuration",
            text: "Training Duration",
        },
        // {
        //     dataField: "valid",
        //     text: "Is Valid",
        // },
        // {
        //     dataField: "modifyDate",
        //     text: "Last Modified",
        // },
        {
            dataField: "orgIssued",
            text: "Training Provider",
            formatter: issueOrgFormatter
        },
        {
            dataField: "equivalentCrsStatus",
            formatter: crsStatusFormatter,
            text: "Course Status",
        },
        {
            dataField: "validity",
            text: "Expiry Date",
            formatter: expiryDateFormatter,
        },
        {
            dataField: "",
            text: "View",
            formatter: buttonFormatter
        },
        {
            dataField: "",
            text: "Apply",
            formatter: applyButtonFormatter
        }


    ];


    // var propsId = props.homeId
    //setcrsId(props.data.crsId);
    if (props.data) {
        //setcrsId(props.data.crsId);
        return (
            <div className='shadow p-3 mb-5 bg-white rounded' style={{ backgroundColor: 'red' }}>
                {showSpinner ? <div style={{ paddingLeft: "50%", paddingTop: "10%", position: "absolute" }}>
                    <Spinner show={showSpinner} animation="border" size="lg" variant='primary' />

                </div> : <div></div>}
                <BootstrapTable columns={columns}
                    keyField="count"
                    data={courseList}
                    filter={filterFactory()}
                    striped
                    hover
                    condensed />
                {/* {show ? <ModalContent /> : null} */}
                <UserBadgeUrlDialog show={show} userId={params} data={dataForModal} handleUpdateCallback={props.handleUpdateCallback} onHide={() => { reloadVersionGrid(false); setshow(false) }} />
                <SuccessPopUp show={showSuccess} data={successMsg} onHide={() => { setShowSuccess(false) }} />
                <ErrorPopUp show={showErrorMsg} data={errorMsg} onHide={() => { setShowErrorMsg(false) }} />
                <Modal show={cartErrorBox} centered onHide={() => setCartErrorBox(false)} closeButton>
                    <Modal.Body className='text-center'>
                    <Typography level="body-sm">{cartErrorBoxMsg}

                            <Tooltip variant="contained" title="Remove from Cart">
                                <IconButton color="primary" onClick={() => { updatePendingCart() }} aria-label="directions-down">
                                    <RemoveShoppingCartOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                    </Typography>
                            <Table borderAxis="x" size="md" style={{ "border-radius": "1.5px" }} stickyHeader variant="plain">
                                                <thead>
                                                    <tr color='primary'>
                                                        <th color='primary'>Course Code</th>
                                                        <th color='primary'>Version Code&nbsp;</th>
                                                        <th>Training Provider</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                        {cartErrorBoxList.map((row,id) => (
                                                            <tr key={(row.courseId == undefined)?row.course_id:row.courseId}>
                                                                <td>{(row.courseId == undefined)?row.course_id:row.courseId}</td>
                                                                <td>{row.tp_course_code}</td>
                                                                <td>{mapOfTpIdName[parseInt((row.tp_id == undefined)?row.training_provider_id:row.tp_id)]}</td>
                                                                
                                                            </tr>
                                                        )) }
                                                    
                                                </tbody>
                                            </Table>

                    </Modal.Body>
                </Modal>
            </div>

        )
    } else {
        return (
            <div>
                <p>Empty</p>
            </div>

        )
    }

}

export default UserCrsVersionsTable;



