import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Container } from 'react-bootstrap';
import * as apiUtility from '../../config/APIUtils';
import SuccessPopUp from '../Utility/SuccessPopUp';
import ErrorPopUp from '../Utility/ErrorPopUp';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import MessageBox from '../Utility/MessageBox';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';


const intialState = {
    tp_id: '',
    provider_name: '',
    primary_contact: {
        status: false,
        first_name: '',
        last_name: '',
        email_id: '',
        user_id: ''
    },
    email: '',
    number: '',
    address: '',
    student_number_regex: '',
    student_number_length: '',
    student_number_check: false
    // student_number: {
    //     max_length: "",
    //     starts_with: "",
    //     special_characters: "",
    //     alpha_numeric: "100",
    //     op1:"-1",
    //     op2:"-1",
    //     op3:"-1",

    // }
}

const passVerificationError = {
    isLengthy: false,
    hasUpper: false,
    hasLower: false,
    hasNumber: false,
    hasSpecChar: false,
    confirmPassword: false
}

var errors = {
    provider_name: false,
    email: false,
    number: false,
    address: false,
    student_number_regex: false,
    student_number_length: false
}

var errorText = {
    provider_name: '',
    email: '',
    number: '',
    address: '',
    student_number_regex: '',
    student_number_length: ''
}


function TrainingProviderForm(props) {
    const BASE_URL_TP_SAVE = "course/create-training-provider";
    const BASE_URL_TP_UPDATE = "course/update-training-provider";
    const BASE_URL_TP_GET = "course/get-training-provider/";
    const URL_VALIDATE_P_CONTACT = "course/validate-primary";

    const [trainingProviderObj, settrainingProviderObj] = useState(-1);
    const [waitBlock, setWaitBlock] = useState(false);
    const [showUserMsg, setShowUserMsg] = useState(false);
    const [userMsg, setUserMsg] = useState('');
    const [validationError, setValidationError] = useState(errors);
    const [errorDesc, setErrorDesc] = useState(errorText);
    const [validated, setValidated] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    var data = props.data;
    useEffect(() => {
        //console.log("Data recived : ",data);
        if (data === undefined || data.tp_id === undefined) {
            settrainingProviderObj(-1);
        } else {
            var apiParams = apiUtility.apiInputRequest;
            apiParams.endPoint = BASE_URL_TP_GET + data.tp_id;
            apiUtility.fetchAPIDataGET(apiParams).then(res => {
                var response = res.data;
                //console.log("Response : ", response);
                if (response !== undefined && response.length > 0) {
                    settrainingProviderObj({ ...response[0] });
                } else {
                    setErrorMsg("Internal server error. Please try again later.");
                    setShowErrorMsg(true);
                }
            })
                .catch(err => {
                    setErrorMsg("Internal server error. Please try again later.");
                    setShowErrorMsg(true);
                    console.error('Request failed:', err);
                })
        }
    }, [props.data]);

    const handleOnChange = e => {
        const { name, value } = e.target

        settrainingProviderObj({ ...trainingProviderObj, [name]: value });
        const length = value.length <= 0;

        if (name === "p_first_name") {
            settrainingProviderObj({
                ...trainingProviderObj, primary_contact: {
                    ...trainingProviderObj.primary_contact, "first_name": value
                }
            });
        }
        if (name === "p_last_name") {
            settrainingProviderObj({
                ...trainingProviderObj, primary_contact: {
                    ...trainingProviderObj.primary_contact, "last_name": value
                }
            });
        }
        if (name === "p_email_id") {
            settrainingProviderObj({
                ...trainingProviderObj, primary_contact: {
                    ...trainingProviderObj.primary_contact, "email_id": value
                }
            });
        }
        if (name === "p_user_id") {
            settrainingProviderObj({
                ...trainingProviderObj, primary_contact: {
                    ...trainingProviderObj.primary_contact, "user_id": value
                }
            });
        }
        if (name === "student_number_check") {
            settrainingProviderObj({
                ...trainingProviderObj, student_number_check: e.target.checked
            });
        }
        if (name === "student_number_regex_detail") {
            settrainingProviderObj({
                ...trainingProviderObj, student_number_length: e.target.value
            });
        }
        if (name === "student_number_length") {
            settrainingProviderObj({
                ...trainingProviderObj, student_number_length: e.target.value
            });
        }
        
        // if (name === "max_length") {
        //     settrainingProviderObj({ ...trainingProviderObj, student_number : {
        //         ...trainingProviderObj.student_number ,  "max_length":value
        //     } });
        // }
        // if (name === "starts_with") {
        //     settrainingProviderObj({ ...trainingProviderObj, student_number : {
        //         ...trainingProviderObj.student_number, "starts_with":value
        //     } });
        // }
        // if (name === "special_characters") {
        //     settrainingProviderObj({ ...trainingProviderObj, student_number : {
        //         ...trainingProviderObj.student_number, "special_characters" : value
        //     } });
        // }
        // if (name === "alpha_numeric") {
        //     settrainingProviderObj({ ...trainingProviderObj, student_number : {
        //         ...trainingProviderObj.student_number, "alpha_numeric" : value
        //     } });
        // }
        // if (name === "op1"){
        //     settrainingProviderObj({ ...trainingProviderObj, student_number : {
        //         ...trainingProviderObj.student_number, "op1" : value
        //     } });
        // }
        // if (name === "op2"){
        //     settrainingProviderObj({ ...trainingProviderObj, student_number : {
        //         ...trainingProviderObj.student_number, "op2" : value
        //     } });
        // }  
        // if(name === "op3"){
        //     settrainingProviderObj({ ...trainingProviderObj, student_number : {
        //         ...trainingProviderObj.student_number, "op3" : value
        //     } });
        // }


        var provider_name = errors["provider_name"];
        var email = errors["email"];
        var number = errors["number"];
        var provider_nameDesc = errorText["provider_name"];
        var emailDesc = errorText["email"];
        var numberDesc = errorText["number"];
        var address = errors["address"];
        var addressDesc = errorText["address"];
        var student_number_regex = errors["student_number_regex"];
        var student_number_regexDesc = errorText["student_number_regex"];
        var student_number_length = errors["student_number_length"];
        var student_number_lengthDesc = errorText["student_number_length"];

        if (name === 'provider_name') {
            errorText["provider_name"] = "";
            provider_nameDesc = "";
            provider_name = false;
            if (length) {
                provider_nameDesc = "Provider Name is mandatory";
                errorText["provider_name"] = provider_nameDesc;
                provider_name = true;
            }
            errors["provider_name"] = provider_name;
        }

        if (name === 'email') {
            emailDesc = "";
            email = false;
            errorText["email"] = "";
            if (length) {
                email = true;
                emailDesc = "Email cannot be left empty";
                errorText["email"] = "Email cannot be left empty";
            } else {
                if (!validateEmail(value)) {
                    email = true;
                    emailDesc = "Email is not valid";
                    errorText["email"] = "Email is not valid";
                }
            }
            errors["email"] = email;
        }

        if (name === 'address') {
            errorText["address"] = "";
            addressDesc = "";
            address = false;
            if (length) {
                addressDesc = "Address is mandatory";
                errorText["address"] = "Address is mandatory";
                address = true;
            }
            errors["address"] = address;
        }

        if (name === 'student_number_regex') {
            errorText["student_number_regex"] = "";
            student_number_regexDesc = "";
            student_number_regex = false;
            if (length) {
                student_number_regexDesc = "Student Number Regex is mandatory";
                errorText["student_number_regex"] = "Student Number Regex is mandatory";
                student_number_regex = true;
            }
            errors["student_number_regex"] = student_number_regex;
        }

        if (name === 'student_number_length') {
            errorText["student_number_length"] = "";
            student_number_lengthDesc = "";
            student_number_length = false;
            if (length) {
                student_number_lengthDesc = "Student Number Length is mandatory";
                errorText["student_number_length"] = "Student Number Length is mandatory";
                student_number_length = true;
            }
            errors["student_number_length"] = student_number_length;
        }

        setValidationError({ ...validationError, provider_name, email, number, address, student_number_regex,student_number_length });
        setErrorDesc({ ...errorDesc, provider_nameDesc, emailDesc, numberDesc, addressDesc, student_number_regexDesc,student_number_lengthDesc });

    };


    const validateEmail = (email) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return (true);
        }
        return (false)
    };

    const validateDate = (value) => {
        var number = errors["number"];
        var numberDesc = errorText["number"];
        errorText["number"] = "";
        numberDesc = "";
        validationError["number"] = false;
        number = false;
        if (value === undefined || value === "") {
            numberDesc = "Contact number is mandatory";
            errorText["number"] = "Contact number is mandatory";
            number = true;
        } else {
            if (!isPossiblePhoneNumber(value)) {
                numberDesc = "Invalid Contact Number";
                errorText["number"] = "Invalid Contact Number";
                number = true;
            }
        }
        trainingProviderObj.number = value;
        errors["number"] = number;
        validationError.number = number;
        errorDesc.number = numberDesc
    }


    const validatePrimaryContact = async e => {
        setWaitBlock(true);
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = URL_VALIDATE_P_CONTACT;
        apiRequest.data = trainingProviderObj;
        await apiUtility.sendAPIDataPOST(apiRequest).then((res) => {
            setTimeout(
                () => {
                    setWaitBlock(false);
                    if (res.data) {
                        setShowUserMsg(true);
                        setUserMsg("Verified successfully");
                        settrainingProviderObj({
                            ...trainingProviderObj, primary_contact: {
                                ...trainingProviderObj.primary_contact, "status": true
                            }
                        });
                    } else if (!res.data) {
                        setErrorMsg("No User found!");
                        setShowErrorMsg(true);
                    } else {
                        setErrorMsg("Something went wrong! Please try again.");
                        setShowErrorMsg(true);
                    }
                },
                1000
            );
        }).catch((err) => {
            setErrorMsg("Something went wrong! Please try again.");
            setShowErrorMsg(true);
        })

    }

    const handleOnSubmit = async e => {
        e.preventDefault();
        console.log(trainingProviderObj);

        try {
            if (trainingProviderObj.tp_id !== undefined && trainingProviderObj.tp_id !== "") {
                var apiRequest = apiUtility.apiInputRequest;
                apiRequest.endPoint = BASE_URL_TP_UPDATE;
                apiRequest.data = trainingProviderObj;
                const res = await apiUtility.sendAsyncAPIDataPOST(apiRequest)
                const resJson = res.data;
                if (resJson !== undefined && resJson) {
                    setValidated(true);
                    setShowUserMsg(true);
                    setUserMsg("Updated successfully");
                }
                else {
                    setErrorMsg("Something went wrong! Please try again.");
                    setShowErrorMsg(true);
                }
            } else {
                var apiRequest = apiUtility.apiInputRequest;
                apiRequest.endPoint = BASE_URL_TP_SAVE;
                apiRequest.data = trainingProviderObj;
                const res = await apiUtility.sendAsyncAPIDataPOST(apiRequest)
                const resJson = res.data;
                if (resJson !== undefined && resJson.tp_id !== undefined) {
                    setValidated(true);
                    setShowUserMsg(true);
                    setUserMsg("Training Provider successfully registered!");
                    settrainingProviderObj({ ...trainingProviderObj, tp_id: resJson.tp_id });
                    let training_providers_session = JSON.parse(sessionStorage.getItem("training_providers"));
                    training_providers_session.push({ "tp_id": resJson.tp_id, "provider_name": resJson.provider_name })
                    sessionStorage.setItem("training_providers", JSON.stringify(training_providers_session));
                }
                else {
                    setErrorMsg("Something went wrong! Please try again.");
                    setShowErrorMsg(true);
                }
            }
        } catch (error) {
            setErrorMsg("Something went wrong! Please try again.");
            setShowErrorMsg(true);
        }
    }

    // const alphaNumericVals = [
    //     {
    //         value: '100',
    //         label: 'NA',
    //     },
    //     {
    //         value: '200',
    //         label: 'Alphabets Only',
    //     },
    //     {
    //         value: '300',
    //         label: 'Numeric Only',
    //     },
    //     {
    //         value: '400',
    //         label: 'Both',
    //     }
    // ];

    return (
        <div style={{ marginBottom: "2pc" }}>
            <Container >
                <Row>
                    {(trainingProviderObj === -1) ?
                        <Row>
                            <MessageBox data="Select a training provider to view" />
                            <Button variant="primary" onClick={() => settrainingProviderObj(intialState)}>
                                Add New Training Provider
                            </Button>
                        </Row>
                        :
                        <Form noValidate validated={validated} onSubmit={handleOnSubmit}>
                            <Row>
                                <Typography align='center' variant='subtitle1'>Training Provider ID : {trainingProviderObj.tp_id}</Typography>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridName1" hasValidation>
                                    <Form.Label>Provider Name</Form.Label >
                                    <Form.Control type="input" name="provider_name" onChange={handleOnChange} isInvalid={!!errorText["provider_name"]} value={trainingProviderObj.provider_name} placeholder="Enter name" />
                                    <Form.Control.Feedback type="invalid">{errorText["provider_name"]}</Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            <br></br>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" name="email" onChange={handleOnChange} isInvalid={!!errorText["email"]} value={trainingProviderObj.email} required placeholder="Enter email" />
                                    {/* <span className='text-danger'>{errorText["email"]}</span> */}
                                    <Form.Control.Feedback type="invalid">{errorText["email"]}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPhoneNo">
                                    <Form.Label>Contact Number</Form.Label>
                                    <PhoneInput
                                        placeholder="Enter contact number"
                                        name="number"
                                        defaultCountry="CA"
                                        value={trainingProviderObj.number}
                                        required={true}
                                        onChange={validateDate} />
                                    <Form.Control.Feedback type="invalid">{errorText["number"]}</Form.Control.Feedback>
                                    {(validationError.number) ? <div className='text-danger' style={{ marginLeft: "2.5pc", fontSize: "0.8rem", marginTop: "2px" }}> <p> {errorText["number"]} </p></div> : ""}
                                </Form.Group>
                            </Row>

                            <br></br>
                            <Row className="mb-3">
                                <Form.Group className="mb-3" as={Col} controlId="formGridaddress">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="input" name="address" onChange={handleOnChange} isInvalid={!!errorText["address"]} value={trainingProviderObj.address} placeholder="Enter full address ..." />
                                    <Form.Control.Feedback type="invalid">{errorText["address"]}</Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            <br></br>
                            <Row>
                                <Accordion >
                                    <AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls={"primary-contact-content"}>
                                        <Typography>
                                            {(trainingProviderObj.primary_contact.status === false) ?
                                                <div><FiXCircle style={{ "color": "red", "fontSize": "1.5pc" }} /> &nbsp;Primary Contact - Not yet verified</div>
                                                :
                                                <div><FiCheckCircle style={{ "color": "green", "fontSize": "1.5pc" }} /> &nbsp;Primary Contact - {trainingProviderObj.primary_contact.first_name + " " + trainingProviderObj.primary_contact.last_name}</div>

                                            }
                                        </Typography>

                                    </AccordionSummary>
                                    {(waitBlock) ?
                                        <div>
                                            <LinearProgress />
                                        </div>
                                        : ""}

                                    <AccordionDetails>
                                        <Grid>
                                            <Form>
                                                <Row>

                                                    <Paper component="form" variant="outlined"
                                                        sx={{ display: 'flex', marginBottom: "4px", alignItems: 'center' }}
                                                    >
                                                        <Form.Group as={Col} className="mb-2" controlId="formBasicUserId">
                                                            <Form.Label>User ID</Form.Label>
                                                            <Form.Control type="input" name='p_user_id' value={trainingProviderObj.primary_contact.user_id} placeholder="Please enter User Id" onChange={handleOnChange} />
                                                        </Form.Group>
                                                        <Divider orientation="vertical" sx={{ color: "#1976d2", borderRadius: 2 }} variant="middle" flexItem>OR</Divider>
                                                        <Form.Group as={Col} className="mb-2" controlId="formBasicEmail">
                                                            <Form.Label>User Email</Form.Label>
                                                            <Form.Control type="input" name='p_email_id' value={trainingProviderObj.primary_contact.email_id} placeholder="Please enter user's Email" onChange={handleOnChange} />
                                                        </Form.Group>
                                                    </Paper>
                                                </Row>
                                                <br></br>
                                                <Row>
                                                    <Form.Group as={Col} controlId="firstName">
                                                        <Form.Label>First Name</Form.Label>
                                                        <Form.Control type="input" name="p_first_name" value={trainingProviderObj.primary_contact.first_name} placeholder="Please enter user's First Name" onChange={handleOnChange} />
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="lastName">
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control type="input" name="p_last_name" value={trainingProviderObj.primary_contact.last_name} placeholder="Please enter user's Last Name" onChange={handleOnChange} />
                                                    </Form.Group>
                                                </Row>
                                                <br></br>
                                                <Button variant="primary" onClick={() => validatePrimaryContact()} >Validate</Button>
                                            </Form>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Row>
                            <br></br>
                            {/* <Divider textAlign="left" role="presentation"><Typography variant='caption' gutterBottom>Student Number Details</Typography></Divider> */}
                            <br></br>
                            <Row className="mb-3">
                                {/* <Col>
                                <Grid item xs={12} md={12}>
                                    <Form.Group className="mb-3" as={Col} controlId="formGridStudentNumberRegex">
                                        <Form.Control type="input" name="student_number_regex" onChange={handleOnChange} isInvalid={!!errorText["student_number_regex"]} value={trainingProviderObj.student_number_regex} placeholder="Enter student number regex ..." />
                                        <Form.Control.Feedback type="invalid">{errorText["student_number_regex"]}</Form.Control.Feedback>
                                    </Form.Group>
                                </Grid>
                                </Col>
                                <Col>
                                <Form.Group className="mb-3" as={Col} controlId="formGridStudentNumberLength">
                                        <Form.Control type="input" name="student_number_length" onChange={handleOnChange} isInvalid={!!errorText["student_number_length"]} value={trainingProviderObj.student_number_length} placeholder="Enter student number length ..." />
                                        <Form.Control.Feedback type="invalid">{errorText["student_number_length"]}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col> */}
                                {/* <Grid container spacing={2}>
                                    <Grid item>
                                        <TextField
                                            id="outlined-multiline-flexible"
                                            label="Length"
                                            multiline
                                            maxRows={4}
                                            helperText="Max length"
                                            name="max_length"
                                            onChange={handleOnChange} 
                                            value={trainingProviderObj.student_number.max_length}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={1}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-select-small-select"
                                            label="Age"
                                            name="op1"
                                            value={trainingProviderObj.student_number.op1}
                                            onChange={handleOnChange}
                                        >
                                            <MenuItem value={-1}>-</MenuItem>
                                            <MenuItem value={10}>AND</MenuItem>
                                            <MenuItem value={20}>OR</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item >
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Alpha-Numeric"
                                            helperText="Contains Alpha Numeric?"
                                            variant="outlined"
                                            name="alpha_numeric"
                                            onChange={handleOnChange} 
                                            value={(trainingProviderObj.student_number.alpha_numeric !== undefined) ? trainingProviderObj.student_number.alpha_numeric : "100"}
                                        >
                                            {alphaNumericVals.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} md={1}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-select-small-select"
                                            name="op2"
                                            onChange={handleOnChange}
                                            value={trainingProviderObj.student_number.op2}
                                            label="Age"
                                        >
                                            <MenuItem value={-1}>-</MenuItem>
                                            <MenuItem value={10}>AND</MenuItem>
                                            <MenuItem value={20}>OR</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item >
                                        <TextField
                                            id="outlined-multiline-flexible"
                                            label="Special Character"
                                            multiline
                                            maxRows={4}
                                            name="special_characters"
                                            helperText="Allowed special characters"
                                            onChange={handleOnChange} 
                                            value={trainingProviderObj.student_number.special_characters}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={1}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-select-small-select"
                                            label="Operation"
                                            name="op3"
                                            onChange={handleOnChange}
                                            value={trainingProviderObj.student_number.op3}
                                        >
                                            <MenuItem value={-1}>-</MenuItem>
                                            <MenuItem value={10}>AND</MenuItem>
                                            <MenuItem value={20}>OR</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item >
                                        <TextField
                                            id="outlined-multiline-flexible"
                                            label="Starts with "
                                            multiline
                                            maxRows={4}
                                            name="starts_with"
                                            helperText="First letter (Case sensitive)"
                                            onChange={handleOnChange} 
                                            value={trainingProviderObj.student_number.starts_with}
                                        />
                                    </Grid>

                                </Grid> */}
                            </Row>
                            {/* <Row className="mb-3">
                                <Grid item xs={12} md={12}>
                                    <Form.Group className="mb-3" as={Col} controlId="formGridStudentNumberRegex">
                                    <Form.Control type="input" name="student_number_regex_detail" onChange={handleOnChange}  value={trainingProviderObj.student_number_regex_detail} placeholder="Details for student no. regex submission" />
                                    </Form.Group>
                                </Grid>
                            </Row>
                            <Row className="mb-3">
                                <Grid item xs={12} md={12}>
                                    <Form.Group className="mb-3" as={Col} controlId="formGridStudentNumberRegex">
                                        <input type="checkbox" name="student_number_check" checked={trainingProviderObj.student_number_check} style={{ margin: "1%", transform: "scale(1.25)" }} onChange={handleOnChange} />
                                        <Form.Label>Allow user to enter student no. while registration</Form.Label>
                                    </Form.Group>
                                </Grid>
                            </Row> */}
                            <Button variant="primary" type="submit" >
                                Submit
                            </Button>

                        </Form>
                    }
                </Row>
                <br></br>

            </Container>
            <SuccessPopUp show={showUserMsg} data={userMsg} onHide={() => { setShowUserMsg(false) }} />
            <ErrorPopUp show={showErrorMsg} data={errorMsg} onHide={() => { setShowErrorMsg(false) }} />
        </div>
    )
}

export default TrainingProviderForm;



