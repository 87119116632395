import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Form, Row, Col, Button, Container, FormText } from "react-bootstrap";
function AboutUsPage() {
  return (
    <div style={{ marginTop: "6pc", marginBottom: "2pc" }}>
      <Container>
        <h2>About Us</h2>
        <br></br>
        <p>
          The Care Passport portal is a centralized training management system
          that helps Ontario long-term care (LTC) homes manage, track, and share
          staff training records — ensuring compliance with provincial
          legislation is simple, standardized, and auditable.<br></br>
          <br></br>
          Employees own their shareable training passports, allowing them to
          bring their training records with them when joining a new LTC home,
          reducing redundancy and speeding up onboarding.<br></br>
          <br></br>
          Training provider offerings listed in the portal are reviewed for
          alignment with legislated requirements, giving employers confidence
          that every course meets mandated standards.<br></br>
          <br></br>
          The Care Passport portal is overseen by CARE-LTC, a sector-led
          organization that understands the compliance challenges LTC homes face
          — because it’s run by professionals from within the sector. Our goal
          is to simplify compliance so you can focus on what matters most: care.
        </p>
      </Container>
    </div>
  );
}

export default AboutUsPage;
