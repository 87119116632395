import { Image } from "react-bootstrap";
import pic from "../homepageImg.jpg";
import { BASE_URL_FRONTEND } from "../config/Url-config";
import { Alert, Box, Button, Typography } from "@mui/joy";
import WarningIcon from "@mui/icons-material/Warning";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Homepage() {
  const navigate = useNavigate();

  const [showTab1, setTab1Show] = useState(false);
  const handleCloseTab1 = () => setTab1Show(false);
  const handleShowTab1 = () => setTab1Show(true);

  const [showTab2, setTab2Show] = useState(false);
  const handleCloseTab2 = () => setTab2Show(false);
  const handleShowTab2 = () => setTab2Show(true);

  const [showTab3, setTab3Show] = useState(false);
  const handleCloseTab3 = () => setTab3Show(false);
  const handleShowTab3 = () => setTab3Show(true);

  const [showNotice, setNoticeShow] = useState(false);
  const handleCloseNotice = () => setNoticeShow(false);
  const handleShowNotice = () => setNoticeShow(true);

  const currentURL = window.location.href;
  if (
    process.env.NODE_ENV === "production" &&
    currentURL.includes("herokuapp.com")
  ) {
    alert("Please use new URL : " + BASE_URL_FRONTEND);
    //return window.location.href = BASE_URL_FRONTEND;
  }
  // let textStyle = {
  //   position: 'absolute',
  //   top: '20%',
  //   left: '70%',
  //   color: "#1d3d80"
  // };

  // let textStyleAlert = {
  //   position: 'absolute',
  //   top: '10%',
  //   left: '3%',
  //   width: "50pc"
  // };

  // let textStyleAlert2 = {
  //   position: 'absolute',
  //   top: '40%',
  //   left: '3%',
  //   width: "50pc"
  // };

  const registerPage = () => {
    return (window.location.href = BASE_URL_FRONTEND + "userRegistration");
  };
  return (
    <Box
      sx={{
        marginTop: "2.8pc",
        position: "relative",
        width: "100%",
        height: { xs: "500px", md: "100%" },
      }}
    >
      {/* <Modal style={{width:"50%",left:"25%",top:"10%"}} show={showTab1} onHide={handleCloseTab1} id="homepageTab1">
          <Modal.Header closeButton>
            <Modal.Title>
            I Have an Account - Take Me To The Login Page
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Forgot your username? [username reset instructions] Forgot your
              password? [password reset instructions] Don’t have an existing
              account? Click here to create an account.
            </p>
          </Modal.Body>

        </Modal> */}
      <Modal
        style={{ width: "50%", left: "30%", top: "10%" }}
        show={showTab2}
        onHide={handleCloseTab2}
        id="homepageTab2"
      >
        <Modal.Header closeButton>
          <Modal.Title>I’m New Here – Help Me Create My Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your Care Passport portal account contains an official record of the
            long- term care home onboarding and annual mandatory training you
            have completed.<br></br><br></br> Your training records stay with you and can be
            shared with any LTC home or employer that uses the portal — no need
            to repeat training when you switch workplaces. You will also receive
            email reminders for any training that is due to expire and needs to
            be renewed.<br></br><br></br> Click the <Link to="/userRegistration">Create Account</Link> button to get started.
          </p>
        </Modal.Body>
      </Modal>
      <Modal
        style={{ width: "50%", left: "30%", top: "10%" }}
        show={showTab3}
        onHide={handleCloseTab3}
        id="homepageTab3"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            We're a New Organization - What Do We Need to Know?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            The Care Passport portal simplifies training management by bringing
            registration, tracking, and reporting together in one place. You
            define the training required for each role in your organization and
            choose the approved training partners that meet your needs. When you
            hire someone who already has a Care Passport, their verified records
            come with them – eliminating wasteful retraining. The portal tracks
            completions and generates detailed reports at the individual, home,
            or organizational level.<br></br><br></br> To get started, contact us. We’ll create
            your organizational account and help you configure it to match your
            staffing roles and training requirements.<br></br><br></br> For more information on
            the Council for Advancement of Resident-care Education in LTC and
            how the Care Passport system works with your home, see CARE-LTC.
            
          </p>
        </Modal.Body>
      </Modal>

      <Modal
        style={{ width: "70%", left: "15%", top: "10%" }}
        show={showNotice}
        onHide={handleCloseNotice}
        id="homepageNotice"
      >
        <Modal.Header closeButton>
          <Modal.Title>
          Training Provider Notice 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><b>
          Effective April 1, 2025, Lambton College has upgraded its online course delivery platform to simplify user access to the LTC microcredential training. These changes include the following:
          <br></br><br></br>
          - you will no longer need a Lambton College Student Number to access training<br></br>
          - you will access new training through a new Moodle portal and not through Lambton College mylambton d2l<br></br>
          - with a new registration, you will receive immediate email instructions from Lambton College on how to access your training<br></br>
          - when you are approved for course registration within the Care Passport portal, you will get access to the courses within an hour<br></br>
          - when you pass the training, the Care Passport portal will be updated to reflect your passed course within an hour<br></br><br></br>
          *NOTE: those enrolled in existing courses using the Lambton College D2L system will complete those courses via mylambton.ca but all new registrations will go through the new system<br></br>
          For any questions or concerns with the transition to the new Training Provider system, please contact LTCorientation@lambtoncollege.ca
          </b>

            
          </p>
        </Modal.Body>
      </Modal>
      <div>
      <p style={{fontSize:"1rem",backgroundColor:"red",color:"white",padding:"5px",cursor:"pointer",marginTop:"1%"}} onClick={handleShowNotice}>Training Provider Notice: Effective April 1, 2025, Lambton College has upgraded its online course delivery platform to simplify user access to the LTC.....<span style={{fontWeight:"bold"}}>see more</span></p>

        <Button
          style={{
            margin: "2%",
            padding: "20px 50px",
            fontSize: "1.2rem",
            height: "6rem",
            // top: "10px",
            width: "22rem",
          }}
          onClick={() => navigate('/login')}
          // onClick={handleShowTab1}
        >
           I Have an Account - Take Me To The Login Page
        </Button>
        <Button
          style={{
            margin: "2%",
            padding: "20px 40px",
            fontSize: "1.2rem",
            maxWidth: "22rem",
          }}
          onClick={handleShowTab2}
        >
          I’m New Here – Help Me Create My Account
        </Button>
        <Button
          style={{
            margin: "2%",
            padding: "20px 40px",
            fontSize: "1.2rem",
            maxWidth: "22rem",
          }}
          onClick={handleShowTab3}
        >
          We're a New Organization - What Do We Need to Know?
        </Button>
      </div>
      <Box
        component="img"
        src={pic}
        alt="Background"
        sx={{ width: "100%", height: "100%", objectFit: "cover" }}
      />

      {/* <Box
        sx={{
          position: 'absolute',
          top: { xs: '40%', md: '10%' },
          left: { xs: '5%', md: '5%' },
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '90%', md: '50%' },
          gap: 2,
        }}
      >
        <Box
          sx={{
            color: 'white',
            borderRadius: '4px',
          }}
        >
          <Alert startDecorator={<WarningIcon />} className='text-left' color="danger" >
            <Typography level="body-sm"> Training Provider Holiday Notice: Lambton College is closed from December 25 until January 1, and will reopen on Thursday, January 2. The following College services will reduced or unavailable:<br></br>
              <Typography level="body-xs" color="danger">- Course registration requests - Reduced services - Processed December 27, 30, 31</Typography><br></br>
              <Typography level="body-xs" color="danger">- Online Education support - Closed and unavailable</Typography><br></br>
              <Typography level="body-xs" color="danger">- College IT services - Closed and unavailable</Typography><br></br>
              <Typography level="body-xs" color="danger">- D2L course access support - Closed and unavailable</Typography><br></br>
              <Typography level="body-xs" color="danger">- Portal requested new Lambton College student number creation - Unavailable - Please contact carepassporthelp@lambtoncollege.ca for alternate means of obtaining a student number</Typography>
            </Typography>
          </Alert>
        </Box>
        <Box
          sx={{
            color: 'white',
            borderRadius: '4px',
          }}
        >
          <Alert startDecorator={<WarningIcon />} color="danger" >
            <Typography level="body-sm" className="text-left"> Care Passport Portal Holiday Notice: Care Passport Portal activities will be available throughout the Holiday season. Response times at carepassporthelp@lambtoncollege.ca may be delayed by up to 24hrs, and requests relating to Training Provider issues will be impacted by College Holiday Closure.</Typography>
          </Alert>
        </Box>
      </Box> */}

      <Box
        sx={{
          position: "absolute",
          top: { xs: "50%", md: "22%", lg:"15%" },
          right: { xs: "auto", md: "5%" },
          bottom: { xs: "5%", md: "auto" },
          width: { xs: "90%", md: "30%" },
          padding: "8px",
          borderRadius: "4px",
          color: "#1d3d80",
        }}
      >
        <h1 className="display-5" style={{ marginTop: "45%" }}>
          Simplify your <br></br>Training Record Management <br></br>
          {/* <Button bsStyle="primary" size="md" onClick={registerPage}>
            SignUp
          </Button> */}
        </h1>
      </Box>
    </Box>
  );

  //   <div style={{ width: 'auto' }}>
  //     <Image style={{ "max-width": "100%", "box-shadow": "0 0 8px 8px white inset", "backgroundSize": 'cover' }} responsive src={pic} />
  //     <Alert startDecorator={<WarningIcon />}  className='text-left' color="danger" style={textStyleAlert}>
  //       <Typography> Training Provider Holiday Notice: Lambton College is closed from December 25 until January 1, and will reopen on Thursday, January 2. The following College services will reduced or unavailable:<br></br>
  //       <Typography color="danger">- Course registration requests - Reduced services - Processed December 27, 30, 31</Typography><br></br>
  //       <Typography color="danger">- Online Education support - Closed and unavailable</Typography><br></br>
  //       <Typography color="danger">- College IT services - Closed and unavailable</Typography><br></br>
  //       <Typography color="danger">- D2L course access support - Closed and unavailable</Typography><br></br>
  //       <Typography color="danger">- Portal requested new Lambton College student number creation - Unavailable - Please contact carepassporthelp@lambtoncollege.ca for alternate means of obtaining a student number</Typography>
  // </Typography>
  // </Alert>
  // <br></br>
  // <Alert startDecorator={<WarningIcon />} color="danger" style={textStyleAlert2}>
  // <Typography className="text-left"> Care Passport Portal Holiday Notice: Care Passport Portal activities will be available throughout the Holiday season. Response times at carepassporthelp@lambtoncollege.ca may be delayed by up to 24hrs, and requests relating to Training Provider issues will be impacted by College Holiday Closure.</Typography>
  // </Alert>
  //     <h1 style={textStyle} className="display-5">Simplify your <br></br>Training Record Management <br></br><Button bsStyle="primary" size="md" onClick={registerPage}>SignUp</Button></h1>

  //   </div>
}

export default Homepage;
