import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Form, Row, Col, Button, Container, FormText } from "react-bootstrap";

function PrivacyPolicyPage() {
  return (
    <div style={{ marginTop: "6pc", marginBottom: "2pc" }}>
      <Container>
        <h2>Privacy Policy</h2>
        <br></br>
        <p>
          This Privacy Policy outlines how we collect, use, disclose, and
          safeguard your information when you use the Care Passport portal. By
          accessing or using carepassport.ca, you consent to the practices
          described in this Privacy Policy.
        </p>
        <br></br>
        <h4>Information we collect </h4>
        <p>
          {" "}
          We collect only the information necessary to provide our services.{" "}
        </p>
        <br></br>
        <h4>Personal Information</h4>
        <p>When users create an account, we collect:</p>
        <ul>
          <li>Full name</li>
          <li>Date of birth</li>
          <li>Email address</li>
          <li>Mailing address</li>
        </ul>
        <h4>Organizational Information</h4>
        <p>When organizations create an account, we collect:</p>
        <ul>
          <li>Organization name and contact information</li>
          <li>
            Names, locations, and contact information for individual homes (if
            applicable)
          </li>
          <li>User roles and associated training requirements</li>
        </ul>
        <h4>How We Use Your Information</h4>
        <p>We use collected information for the following purposes:</p>
        <ul>
          <li>To provide and manage access to training and training records</li>
          <li>To track and report compliance with training requirements</li>
          <li>To generate compliance reports for employers</li>
          <li>
            To facilitate record-sharing when users move between LTC homes
          </li>
          <li>
            To send notifications and reminders about upcoming or expiring
            training
          </li>
        </ul>
        <h4>Sharing of Information</h4>
        <p>
          We do not sell or rent personal information to third parties.
          Information may be shared only as necessary to provide services, such
          as:
        </p>
        <ul>
          <li>
            With authorized training providers for course delivery and record
            confirmation
          </li>
          <li>
            With employers or staffing organizations who the user has agreed to
            share their information with
          </li>
          <li>
            With third-party service providers who assist us in operating
            carepassport.ca (e.g., secure cloud storage, analytics,
            communication tools). These providers are contractually bound to
            safeguard your information and use it only for specified purposes.
          </li>
          <li>
            When required by law or regulatory authorities (e.g., during
            Ministry inspections)
          </li>
        </ul>
        <p>
          Users have control over their own training records and may choose to
          share them when transitioning to another LTC home or staffing
          organization.
        </p>
        <h4>Data Security</h4>
        <p>
          We take reasonable administrative, technical, and physical measures to
          protect your information from unauthorized access, loss, or misuse.
          This includes:
        </p>
        <ul>
          <li>Secure data encryption</li>
          <li>Access controls based on user roles</li>
          <li>Regular security audits and updates</li>
        </ul>
        <h4>Data Storage</h4>
        <p>
          All data collected through carepassport.ca is stored on secure servers
          located in Canada. This ensures compliance with Canadian privacy laws,
          including the Personal Information Protection and Electronic Documents
          Act (PIPEDA).
        </p>
        <h4>Access and Correction</h4>
        <p>
          Users may access, review, and update their personal information at any
          time through their carepassport.ca account. Employers may access
          organizational data relevant to compliance and training tracking.
        </p>
        <h4>Data Retention</h4>
        <p>
          We retain personal and organizational data only as long as necessary
          to fulfill the purposes outlined above or as required by law. When
          data is no longer needed, it is securely deleted or anonymized.
        </p>
        <h4>Cookies and Analytics</h4>
        <p>
          We use cookies and similar tracking technologies to improve user
          experience and analyze website performance. Cookies are small text
          files stored on your device that help us:
        </p>
        <ul>
          <li>Remember your preferences and login status</li>
          <li>Understand how visitors interact with the site</li>
          <li>Identify and fix technical issues</li>
        </ul>
        <p>
          You can choose to disable cookies through your browser settings;
          however, this may affect the functionality of certain features.
        </p>
        <h4>Third-Party Analytics:</h4>
        <p>
          We use secure third-party analytics tools to help us understand site
          usage and improve services. These tools may collect anonymized data
          about user interactions with the portal. No personally identifiable
          information is shared through these tools.
        </p>
        <h4>Third-Party Services</h4>
        <p>
          carepassport.ca may provide links to third-party training providers.
          We are not responsible for the privacy practices of those external
          sites. We encourage users to review their privacy policies.
        </p>
        <h4>Changes to This Policy</h4>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page with an updated effective date. Continued use
          of carepassport.ca after changes have been posted means you accept the
          revised policy.
        </p>
        <h4>Contact Us</h4>
        <p>
          If you have any questions about this Privacy Policy or how your
          information is handled, please contact us at: privacy@carepassport.ca
        </p>
      </Container>
    </div>
  );
}

export default PrivacyPolicyPage;
