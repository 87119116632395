import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Form, Row, Col, Button, Container, FormText } from "react-bootstrap";

function TermsOfUsePage() {
  return (
    <div style={{ marginTop: "6pc", marginBottom: "2pc" }}>
      <Container>
        <h2>Terms of Use</h2>
        <p/>
        <p>
          Terms of Use govern your access to and use of the Care Passport
          portal. By creating an account or using the site, you agree to be
          bound by these Terms.
        </p>
        Please read them carefully.<p/>
        <h4>Purpose of carepassport.ca</h4>
        <p>The portal provides a centralized system for:</p>
        <ul>
          <li>
            LTC homes and staffing organizations to manage and track training
            requirements for employees, volunteers and students.
          </li>
          <li>
            Individuals to access, complete, and maintain records of legislated
            training, which can be shared between employers.
          </li>
        </ul>
        <p>
          The platform is designed to support compliance with Ontario’s
          long-term care legislation.
        </p>
        <h4>User Accounts</h4>
        <p>Users are responsible for:</p>
        <ul>
          <li>Keeping login credentials confidential.</li>
          <li>Ensuring the accuracy of account information.</li>
          <li>All activity under their account.</li>
        </ul>
        Misuse of your account or unauthorized access to another user’s account
        is prohibited.<p/>
        <h4>Acceptable Use</h4>
        You agree to use carepassport.ca only for its intended purpose and in
        accordance with all applicable laws.<p/>
        Prohibited actions include:<p/>
        <ul>
          <li>Uploading false or misleading training records</li>
          <li>Attempting to access unauthorized data or systems</li>
          <li>
            Using the platform for any purpose not related to training or
            compliance in long-term care settings
          </li>
        </ul>
        CARE-LTC reserves the right to suspend or terminate access for users who
        violate these terms.<p/>
        <h4>Employer-Specific Obligations</h4>
        Organizations using carepassport.ca are responsible for:
        <ul>
          <li>
            Ensuring only authorized personnel manage organizational accounts.
          </li>
          <li>
            Accurately assigning roles and training requirements for users
            connected to their organization.
          </li>
          <li>
            Accurately assigning roles and training requirements for users
            connected to their organization.
          </li>
        </ul>
        Employers must use the portal in compliance with applicable laws and
        regulations governing training and employment in the long-term care
        sector.<p/>
        <h4>Training Content and Providers</h4>
        CARE-LTC does not deliver training. All training accessed through the
        Care Passport portal is provided by third-party training providers.
        <p/>
        CARE-LTC ensures that all training available through the portal aligns
        with legislated requirements. Employers are responsible for selecting
        training providers and determining which providers meet their
        organizational needs.<p/>
        <h4>Third-Party Tools and Services</h4>
        carepassport.ca may rely on third-party tools and service providers for
        platform functionality, such as:
        <ul>
          <li>Secure cloud hosting</li>
          <li>Data analytics</li>
          <li>Communication systems (e.g., email notifications)</li>
        </ul>
        <p>
          CARE-LTC takes reasonable steps to ensure these providers uphold
          privacy and security standards. However, CARE-LTC is not liable for
          any issues that arise from third-party service outages, errors, or
          data processing activities beyond our control.
        </p>
        <p>
          Use of third-party training platforms, tools, or links from
          carepassport.ca is at your own discretion, and those services may be
          governed by separate terms of use.
        </p>
        <h4>Intellectual Property</h4>
        All content, design, and functionality of carepassport.ca are the
        intellectual property of CARE-LTC or its licensors.<p/>
        You may not copy, modify, distribute, or reverse-engineer any part of
        the platform without express written permission from CARE-LTC.<p/>
        <h4>Data Privacy and Security</h4>
        Your use of carepassport.ca is also governed by our{" "}
        <a href="www.carepassport.ca/privacy_policy">Privacy Policy</a>, which
        outlines how your data is collected, used, and protected.
        <p/>
        We take reasonable steps to safeguard data and ensure compliance with
        Canadian privacy laws, including PIPEDA.<p/>
        <h4>Availability and Maintenance</h4>
        CARE-LTC strives to provide reliable access to the portal but does not
        guarantee uninterrupted service. Maintenance or technical issues may
        temporarily affect access.<p/>
        Advance notice will be provided where possible for planned maintenance.<p/>
        <h4>Termination of Service</h4>
        CARE-LTC may suspend or terminate access to carepassport.ca for any user
        or organization that:
        <ul>
          <li>Violates these Terms of Use</li>
          <li>Engages in misuse, fraud, or abuse of the portal</li>
          <li>Interferes with the integrity or security of the platform</li>
        </ul>
        Users and organizations may also request account closure at any time.
        Upon termination, access to training records and organizational data may
        be limited or removed, subject to data retention policies and legal
        requirements.<p/>
        <h4>Limitation of Liability and Waivers</h4>
        CARE-LTC is not liable for:
        <ul>
          <li>Any loss, damage, or delay due to user actions or omissions</li>
          <li>
            Errors in training records submitted by third-party providers or
            employers
          </li>
          <li>Interruptions or unavailability of service</li>
          <li>
            Any damages resulting from use or inability to use the portal,
            including lost data, lost revenue, or other incidental or
            consequential damages
          </li>
        </ul>
        The portal and its contents are provided “as is” and without warranties
        of any kind, either express or implied.<br></br>
        Users waive any right to claim damages against CARE-LTC beyond the
        amount paid for services, if any.<p/>
        <h4>Jurisdiction</h4>
        These Terms are governed by the laws of the Province of Ontario, Canada,
        and any disputes shall be resolved in the courts of Ontario. Users agree
        to submit to the exclusive jurisdiction of these courts.<p/>
        <h4>Changes to These Terms</h4>
        We may update these Terms from time to time. Changes will be posted here
        with an updated effective date. Continued use of carepassport.ca after
        changes means you accept the revised terms.<p/>
        <h4>Contact Us</h4>
        For questions or concerns about these Terms, please contact us at
        terms@carepassport.ca
      </Container>
    </div>
  );
}

export default TermsOfUsePage;
