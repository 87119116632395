import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Form, Row, Col, Button, Container, FormText } from "react-bootstrap";

function ContactUsPage() {
  
  return (
    <div style={{ marginTop: "6pc", marginBottom: "2pc" }}>
      <Container>
        <h2>Contact Us</h2>
        <br></br>
        <p>For more information or to arrange a demo of the Care Passport portal, 
        email us atcarepassporthelp@lambtoncollege.ca</p>
        </Container>
        </div>
  )
}

export default ContactUsPage;
