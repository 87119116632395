import React, { useState } from "react";
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Row, Col, Modal } from "react-bootstrap";
import { SlUserUnfollow } from "react-icons/sl";
import { BASE_URL_FRONTEND } from '../../config/Url-config';
import ForgotPassword from "../user_registration/ForgotPassowrd";
import SuccessPopUp from "../Utility/SuccessPopUp";
import LoginVerificationModal from "./LoginVerificationModal";
import * as apiUitlity from '../../config/APIUtils';
import ForgotUsername from "../user_registration/ForgotUsername";
import Header from "../header/Header";

//import GoogleIcon from './GoogleIcon';

// interface FormElements extends HTMLFormControlsCollection {
//   email: HTMLInputElement;
//   password: HTMLInputElement;
//   persistent: HTMLInputElement;
// }
// interface SignInFormElement extends HTMLFormElement {
//   readonly elements: FormElements;
// }

function ColorSchemeToggle(props) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => setMounted(true), []);

  return (
    <IconButton
      aria-label="toggle light/dark mode"
      size="sm"
      variant="outlined"
      disabled={!mounted}
      onClick={(event) => {
        setMode(mode === 'light' ? 'dark' : 'light');
        onClick?.(event);
      }}
      {...other}
    >
      {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

export default function Login() {

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showUserMsg, setShowUserMsg] = useState(false);
    const [userMsg, setUserMsg] = useState('');
    const [verificationModal, setVerificationModal] = useState(false);
    const [show, setshow] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMsg, setsuccessMsg] = useState(false);
    const [showUsername, setShowUsername] = useState(false);
 const [showNotice, setNoticeShow] = useState(false);
  const handleCloseNotice = () => setNoticeShow(false);
  const handleShowNotice = () => setNoticeShow(true);
    const currentURL = window.location.href;
    if (process.env.NODE_ENV === 'production' && currentURL.includes("herokuapp.com")) {
        //alert("Please use new URL : " + BASE_URL_FRONTEND)
        BASE_URL_FRONTEND = 'https://lcpt-prod.herokuapp.com/';
        //return window.location.href = BASE_URL_FRONTEND;
    }

    const handleOnChange = e => {
        const { name, value } = e.target;

        switch (name) {
            case "username":
                setUsername(value);
                break;

            case "password":
                setPassword(value);
                break;

            default:
                break;
        }
    };

    const handleOnSubmit = async e => {


        e.preventDefault();
        setVerificationModal(false);
        if (!username || !password) {
            return alert("Fill up all the form!");
        }
        try {
            var apiRequest = apiUitlity.apiInputRequest;
            apiRequest.endPoint = "login/";
            apiRequest.data = { login_id: username, password: password }
            var res = await apiUitlity.sendAPIDataPOST(apiRequest);
            if (res.data !== 'No User Found!' && res.data !== 'Not verified' && res.data !== 'Account Deactivated') {
                sessionStorage.setItem("authToken", (res.data.token));
                sessionStorage.setItem("userTypeArr", JSON.stringify(res.data.res[0].typeArr));
                sessionStorage.setItem("userId", res.data.res[0].user_id)
                sessionStorage.setItem("userName", res.data.res[0].login_id)
                sessionStorage.setItem("fistName", res.data.newRes[0].firstName)
                sessionStorage.setItem("fullName", res.data.newRes[0].firstName + " " + res.data.newRes[0].lastName);
                sessionStorage.setItem("home_types", JSON.stringify(res.data.appHomeTypeConstants[0].values));
                sessionStorage.setItem("training_providers", JSON.stringify(res.data.training_providers));
                var typeArr = res.data.res[0].typeArr
                var homeArray = []
                var orgArray = []
                for (var i = 0; i < typeArr.length; i++) {
                    if (typeArr[i].permission_type === "organization" && typeArr[i].status === "Active") {
                        orgArray.push((res.data.res[0].typeArr[i].permission_of_id))
                    }
                    else if (res.data.res[0].typeArr[i].permission_type === "home" && res.data.res[0].typeArr[i].status === "Active")
                        homeArray.push((res.data.res[0].typeArr[i].permission_of_id))
                    else if (res.data.res[0].typeArr[i].permission_type === "admin" && res.data.res[0].typeArr[i].status === "Active") {
                        sessionStorage.setItem("userType", "admin")
                        return window.location.href = BASE_URL_FRONTEND + "admin_home/";
                    }
                }

                if (orgArray.length != 0) {
                    sessionStorage.setItem("orgId", orgArray[0]);
                    sessionStorage.setItem("OtherOrgId", JSON.stringify(orgArray));
                    sessionStorage.setItem("homeId", homeArray[0]);
                }
                else {
                    if (sessionStorage.getItem("OtherOrgId"))
                        sessionStorage.removeItem("OtherOrgId")
                    if (sessionStorage.getItem("orgId"))
                        sessionStorage.removeItem("orgId")
                }
                if (homeArray.length != 0) {
                    sessionStorage.setItem("allHomeArr", JSON.stringify(homeArray));
                    sessionStorage.setItem("homeId", homeArray[0]);
                }
                return window.location.href = BASE_URL_FRONTEND + "user/" + res.data.res[0].user_id
            } else {
                if (res.data === 'Not verified') {
                    setVerificationModal(true);
                } else if (res.data === 'Account Deactivated') {
                    setUserMsg("Account Deactivated! \nPlease contact administrator to enable.");
                    setShowUserMsg(true);
                } else {
                    setUserMsg("Invalid Credentials. Please try again.");
                    setShowUserMsg(true);
                }
            }
        } catch (error) {
            setUserMsg("Invalid Credentials. Please try again.");
            setShowUserMsg(true);
        }
    };

    const handleUpdateCallback = (result) => {
        setshow(false);
        setVerificationModal(false);
        setsuccessMsg(result);
        setShowSuccess(true);
    }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    
    <CssVarsProvider defaultMode="light" disableTransitionOnChange>
      <Header/>
       <Modal
              style={{ width: "70%", left: "15%", top: "10%" }}
              show={showNotice}
              onHide={handleCloseNotice}
              id="homepageNotice"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{color:"black"}}>
                Training Provider Notice 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p style={{color:"black"}}><b>
                Effective April 1, 2025, Lambton College has upgraded its online course delivery platform to simplify user access to the LTC microcredential training. These changes include the following:
                <br></br><br></br>
                - you will no longer need a Lambton College Student Number to access training<br></br>
                - you will access new training through a new Moodle portal and not through Lambton College mylambton d2l<br></br>
                - with a new registration, you will receive immediate email instructions from Lambton College on how to access your training<br></br>
                - when you are approved for course registration within the Care Passport portal, you will get access to the courses within an hour<br></br>
                - when you pass the training, the Care Passport portal will be updated to reflect your passed course within an hour<br></br><br></br>
                *NOTE: those enrolled in existing courses using the Lambton College D2L system will complete those courses via mylambton.ca but all new registrations will go through the new system<br></br>
                For any questions or concerns with the transition to the new Training Provider system, please contact LTCorientation@lambtoncollege.ca
                </b>
      
                  
                </p>
              </Modal.Body>
            </Modal>
      <p style={{fontSize:"1rem",backgroundColor:"red",color:"white",padding:"5px",cursor:"pointer"}} onClick={handleShowNotice}>Training Provider Notice: Effective April 1, 2025, Lambton College has.....<span style={{fontWeight:"bold"}}>see more</span></p>

      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width: { xs: '100%', md: '50vw' },
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {/* <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
              <IconButton variant="soft" color="primary" size="sm">
                <BadgeRoundedIcon />
              </IconButton>
              <Typography level="title-lg">Ontario Long Term Care Orientation Co-operative Webportal</Typography>
            </Box> */}
            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: 'hidden',
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography component="h1" level="h3">
                  Sign in
                </Typography>
                <Typography level="body-md">
                  New to portal?{' '}
                  <Link href="/userRegistration" level="title-sm">
                    Sign up!
                  </Link>
                </Typography>
              </Stack>
              {/* <Button
                variant="soft"
                color="neutral"
                fullWidth
              // startDecorator={<GoogleIcon />}
              >
                Continue with Google
              </Button> */}
            </Stack>
            <Divider
              sx={(theme) => ({
                [theme.getColorSchemeSelector('light')]: {
                  color: { xs: '#FFF', md: 'text.tertiary' },
                },
              })}
            >
              or
            </Divider>
            <Stack gap={4} sx={{ mt: 2 }}>
              <form
                onSubmit={
                  // (e) => {
                  // e.preventDefault();
                  // const formElements = e.currentTarget.elements;
                  // const data = {
                  //   email: formElements.email.value,
                  //   password: formElements.password.value,
                  //   persistent: formElements.persistent.checked,
                  // };
                  // alert(JSON.stringify(data, null, 2));
                  handleOnSubmit
              //  }
              }
              >
                <FormControl required>
                  <FormLabel>Username</FormLabel>
                  <Input type="text" name="username" value={username} onChange={handleOnChange}/>
                </FormControl>
                <FormControl required>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    onChange={handleOnChange}
                    value={password}
                    endDecorator={
                      // <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                      // </InputAdornment>
                    }
                  />
                </FormControl>
                <Stack gap={4} sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox size="sm" label="Remember me" name="persistent" />
                    {/* <Link level="title-sm" onClick={() => setShowUsername(true)}>
                      Forgot your Username
                    </Link>
                    <Link level="title-sm" onClick={() => setshow(true)}>
                      or Password?
                    </Link> */}
                  </Box>
                  <Button type="submit" fullWidth>
                    Sign in
                  </Button>
                  <Row>
                        <Col>
                            <Typography level="body-md" style={{ display: "inline-block" }} align="left" sx={{ color: 'text.secondary' }}> Forgot&nbsp; </Typography>
                            <Typography level="body-md" style={{ "color": "#0f6fc5", "cursor": "pointer", display: "inline-block", "textDecoration": "underline" }} align="left" onClick={() => setShowUsername(true)} sx={{ color: 'text.secondary' }}>Username</Typography>
                            <Typography level="body-md" style={{ display: "inline-block" }} align="left" sx={{ color: 'text.secondary' }}> &nbsp;or&nbsp; </Typography>
                            <Typography level="body-md" style={{ "color": "#0f6fc5", "cursor": "pointer", display: "inline-block", "textDecoration": "underline" }} onClick={() => setshow(true)}>Password?</Typography>
                        </Col>
                    </Row>
                    {/* <br></br>
                    <Row>
                        <Col>
                            Are you new here? <a href="/userRegistration" style={{ "color": "#0f6fc5", "cursor": "pointer" }}>Register Now</a>
                        </Col>
                    </Row> */}
                    <Row className="py-4">
                        <Col>
                        <Typography level="body-xs" > <a href="/oldlogin" style={{ "color": "#0f6fc5", "cursor": "pointer" }}>Classic login screen</a></Typography>
                        </Col>
                    </Row>
                </Stack>
              </form>
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-sm" textAlign="center">
              © Carepassport {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: { xs: 0, md: '50vw' },
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage:
              'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
          },
        })}
      />
      <Modal show={showUserMsg} centered style={{ "bordeRadius": "0px", "width": "20pc", "top": "0%", "left": "40%" }} onHide={() => setShowUserMsg(false)}>
                    <Modal.Body className='text-center'>
                        <h1><SlUserUnfollow style={{ "fontSize": "5pc", "color": "#0f6fc5" }} /></h1> <br></br><p className="text-muted">{userMsg}</p>
                    </Modal.Body>
                </Modal>
                <ForgotPassword show={show} handleUpdateCallback={handleUpdateCallback} onHide={() => { setshow(false) }} />
                <ForgotUsername show={showUsername} handleUpdateCallback={handleUpdateCallback} onHide={() => { setShowUsername(false) }} />
                <SuccessPopUp show={showSuccess} data={successMsg} onHide={() => { setShowSuccess(false) }} />
                <LoginVerificationModal show={verificationModal} username={username} handleUpdateCallback={handleUpdateCallback} onHide={() => { setVerificationModal(false) }} />
    </CssVarsProvider>
  );
}